import React, { useEffect, useState } from "react";
import { StyledContainer, StyledPageTitle, StyledOrderBlock } from "./style";
import ReactDataTable from "../../../components/ReactDataTable/ReactDataTable";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersList } from "../../../features/order/orderSlice";
import { toast } from "react-toastify";
import { encryptVal } from "../../../utils/utility";
import moment from "moment";

const RefundOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refundOrdersList = useSelector((state) => state?.order?.ordersList);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(
      getOrdersList({
        orderName: "refundorder",
        page: page,
        perPage: limit,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.status === 401) {
          toast.error(`${res?.message}`, { className: "toast-message" });
          localStorage.removeItem("user");
          navigate("/");
        }
      });
    document.title = "Refund Orders | KeTA Visa Application";
  }, [dispatch, navigate, page, limit]);

  const tableData = refundOrdersList;
  const handleClick = () => {
    window.location.reload();
  };
  const columns = [
    {
      name: "Order Id",
      width: "10rem",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Email",
      width: "15rem",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Telephone",
      selector: (row) => row.telephone,
      sortable: true,
    },
    {
      name: "Date & Time",
      width: "10rem",
      selector: (row) =>
        row.date
          ? moment(new Date(row.date)).format("DD-MM-YYYY hh:mm:ss")
          : "",
      sortable: true,
    },
    {
      name: "Refund Date",
      width: "12rem",
      selector: (row) =>
        row?.refundDate
          ? moment(new Date(row.refundDate)).format("DD-MM-YYYY hh:mm:ss")
          : "-",
      sortable: true,
    },
    {
      name: "Status",
      // width: "8rem",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) =>
        tableData?.length ? (
          <span>
            <Link
              to={`/admin/view-order/${encryptVal(row?.id)}`}
              style={{
                border: "1px solid #18f",
                borderRadius: "5px",
                padding: " 0 5px",
                minWidth: "80px",
                display: "inline-block",
                textAlign: "center",
                height: "40px",
                lineHeight: "40px",
                color: "#111",
                margin: "5px",
                textDecoration: "none",
              }}
              className="blue-border"
            >
              View
            </Link>{" "}
          </span>
        ) : (
          " "
        ),
    },
  ];

  return (
    <StyledContainer>
      <StyledPageTitle>
        <h1>Refund Orders</h1>
        <button type="button" className="btn btn-success" onClick={handleClick}>
          Refresh
        </button>
      </StyledPageTitle>
      <form>
        <StyledOrderBlock>
          <div className="table-block table-responsive">
            <ReactDataTable
              customColumns={columns}
              data={tableData}
              setPage={setPage}
              setLimit={setLimit}
              buttonWrapper={"refund"}
              orderName="refundorder"
            />
          </div>
        </StyledOrderBlock>
      </form>
    </StyledContainer>
  );
};

export default RefundOrders;
